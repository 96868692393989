import React, { cloneElement, createElement, isValidElement, ReactElement } from "react";
import {
  DefaultFormSuggestionGroupProps,
  PlasmicFormSuggestionGroup
} from "./plasmic/imbas_23_fpre/PlasmicFormSuggestionGroup";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { AlgoButtonProps } from "./AlgoButton";
import TextInput from "./TextInput";
import flattenChildren from "react-keyed-flatten-children";
import { useDataStore } from "../store/DataStoreProvider";
import { get } from "lodash";
import { deriveIsoLocale, useRegionContext } from "../context/RegionContext";

type FormSuggestionGroupValue = string | number | boolean | null;

export interface FormSuggestionGroupProps extends DefaultFormSuggestionGroupProps {
  value: FormSuggestionGroupValue;
  onChange: (value: FormSuggestionGroupValue) => void;
  name: string;
}

const FormSuggestionGroup_ = (
  { onChange, value, name, ...props }: FormSuggestionGroupProps,
  ref: HTMLElementRefOf<"div">
) => {
  const input = flattenChildren(props.input)[0];
  const inputElement = isValidElement(input)
    ? cloneElement(input as ReactElement, { name, onChange, value })
    : createElement(TextInput, { name, onChange, value: String(value) });
    
  const { dataStore } = useDataStore();
  const algoValue = props.algoName && dataStore 
    ? get(dataStore, props.algoName) ?? ''
    : '';
  
  const hasNoAlgoButton= props.algoName && algoValue ? false : true;

  const { language, country } = useRegionContext();
  const formatAlgoValue = (value: number): string => { 
    const locale = deriveIsoLocale(country, language);
    return new Intl.NumberFormat(locale).format(Number(value));
  };
  const algoNumber = algoValue? formatAlgoValue(algoValue) : 'N/A';


  return <PlasmicFormSuggestionGroup
    root={{ ref }}
    {...props}
    input={inputElement}
    algoValue={ algoNumber ?? ''}
    hasNoAlgoButton={hasNoAlgoButton}
    algoButton={{
      onClick: () => {
        onChange(algoValue)}
    } as AlgoButtonProps}
  />;
};

const FormSuggestionGroup = React.forwardRef(FormSuggestionGroup_);

export default FormSuggestionGroup;
