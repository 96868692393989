import * as React from "react";
import {  DatePicker, ConfigProvider } from 'antd';
import de from 'dayjs/locale/de';
import en from 'dayjs/locale/en';
import fr from 'dayjs/locale/fr';
import it from 'dayjs/locale/it';
import DE from 'antd/locale/de_DE';
import EN from 'antd/locale/en_US';
import FR from 'antd/locale/fr_FR';
import IT from 'antd/locale/it_IT';
import {
  // PlasmicDateSetter,
  DefaultDateSetterProps
} from "./plasmic/imbas_23_fpre/PlasmicDateSetter";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import dayjs, { Dayjs } from "dayjs";
import { useFormContext } from "react-hook-form";
import { Locale } from 'antd/es/locale';
import "./DateSetterStyles.css";
import { Country, deriveIsoLocale, Language, useRegionContext } from "../context/RegionContext";


export interface DateSetterProps extends DefaultDateSetterProps {
  name: string;
  value: string;
}

function DateSetter_(
  { value, ...props }: DateSetterProps, 
  ref: HTMLElementRefOf<"div">
) {
  const getLocale = (country: Country, language: Language): { locale: Locale, format: string } => {
    
  const locale = deriveIsoLocale(country, language);
  switch (locale) {
    case "de-CH":
      dayjs.locale(de);
      return { locale: DE, format: "DD.MM.YYYY" };
    case "de-DE":
      dayjs.locale(de);
      return { locale: DE, format: "DD.MM.YYYY" };
    case "en-US":
      dayjs.locale(en);
      return { locale: EN, format: "DD/MM/YYYY" };
    case "fr-CH":
      dayjs.locale(fr);
      return { locale: FR, format: "DD.MM.YYYY" };
    case "fr-LU":
      dayjs.locale(fr);
      return { locale: FR, format: "DD/MM/YYYY" };
    case "de-LU":
      dayjs.locale(de);
      return { locale: DE, format: "DD/MM/YYYY" };
    case "it-CH":
      dayjs.locale(it);
      return { locale: IT, format: "DD.MM.YYYY" };
    default:
      dayjs.locale(de);
      return { locale: DE, format: "DD.MM.YYYY" };
  }
};

const { language, country } = useRegionContext();
const { locale, format } = getLocale(country, language);
const { setValue } = useFormContext(); 


const handleDatePickerChange = (date:Dayjs, dateString:string | string[] | undefined) :void => {
  if (date === null) {
  setValue(props.name, null);} 
  else {
    const dateOnly = date.format("YYYY-MM-DD");
  setValue(props.name, dateOnly);}
}


  return (
    <ConfigProvider locale={locale}>
     <DatePicker 
     {...props} 
     onChange={handleDatePickerChange} 
     value={value ? dayjs(value) : null}
     className="custom-date-picker"
     format={format}
    />
    </ConfigProvider>
  );
}

const DateSetter = React.forwardRef(DateSetter_);
export default DateSetter;