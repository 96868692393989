// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type CodeIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function CodeIcon(props: CodeIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M23 5H12.5l-3-4H1a1 1 0 00-1 1v18a3 3 0 003 3h18a3 3 0 003-3V6a1 1 0 00-1-1zM10.414 17L9 18.414 4.586 14 9 9.586 10.414 11l-3 3zM15 18.414L13.586 17l3-3-3-3L15 9.586 19.414 14z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default CodeIcon;
/* prettier-ignore-end */
