import * as React from "react";
import {
  PlasmicTextInput,
  DefaultTextInputProps
} from "./plasmic/imbas_23_fpre/PlasmicTextInput";
import { TextInputRef } from "@plasmicapp/react-web";
import { isEmptyUndefinedOrNull } from "../utils";
import { deriveIsoLocale, useRegionContext } from "../context/RegionContext";

export interface TextInputProps extends Omit<DefaultTextInputProps, "onChange" | "value"> {
  onChange: (value: string | null) => void;
  value: string | null;
}

function TextInput_<T extends string | null>(
  { onChange, value, ...props }: Partial<TextInputProps>,
  ref: TextInputRef
) {
  const { plasmicProps } = PlasmicTextInput.useBehavior<DefaultTextInputProps>(
    props,
    ref
  );
  const { language, country } = useRegionContext();
  const locale = deriveIsoLocale(country, language);
  const [inputValue, setInputValue] = React.useState<string | null>(value ?? null); 
  const decimalSeparator = (() => {
    switch (locale) {
      case 'en-US':
      case 'de-CH':
      case 'it-CH':
        return '.';
      case 'de-DE':
      case 'de-LU':
      case 'fr-CH':
      case 'fr-LU':
        return ',';
      default:
        return '.';
    }
  })();

  React.useEffect(() => {
    setInputValue(value ?? null);
  }, [value]);
  
  const isInvalidValue = (value: string): boolean => {
    return value === '-' || value.endsWith(decimalSeparator);
  };
  const isDecimal = (value: string): boolean => {
    return value.includes(decimalSeparator);
  };
  const isNegative = (value: string): boolean => {
    return value.startsWith('-');
  };
  
  function formatValue(value: string | undefined): string | undefined { 
    const numberFormat = new Intl.NumberFormat(locale);
    if (value === undefined || value === 'null') {
      return;
    } else {
      value = value.toString();
    }
    if (value === '-' || value === decimalSeparator) {
      return value;
    } else if (value.toString().endsWith(decimalSeparator)) {
      value = numberFormat.format(parseInt(value)) + decimalSeparator;
    } else {
      value = numberFormat.format(parseFloat(value.toString().replace(decimalSeparator, '.')));
    }
    return value;
  }

  function removeNonNumericChars(value : string): string{
    if (props.inputType === 'formattedNumber') {
      if (decimalSeparator === ',') {
        value = value.replace(/[^-\d,]/g, '');
      } else if(decimalSeparator === '.' ) {
        value = value.replace(/[^-\d.]/g, '');
      }
    } 
    else {
      value = value.replace(/[^\d]/g, '')
    }
    return value; 
  }

  function limitPastedValues(value : string): string{
    const parsedValue = parseFloat(value.replace(decimalSeparator, '.')); 
    const safeInt = 2147483647;   
    if (parsedValue > safeInt) {
      value = value.substring(0, 9);
    } else if (parsedValue < -(safeInt)) {
      value = value.substring(0, 10);
    }
    return value; 
  }

  function limitCharAmount(value : string): string{ 
    const[integerPart, decimalPart] = value.split(decimalSeparator);
    const decimalLength = decimalPart?.substring(0, 2);
    const posIntegerLength = integerPart?.substring(0, 9);
    const negIntegerLength = integerPart?.substring(0, 10);

    if (isNegative(value) && !isDecimal(value)) {
      value = negIntegerLength;
    } else if ( !isNegative(value) && isDecimal(value)) {
      value = posIntegerLength + decimalSeparator + decimalLength;
    } else if (isNegative(value) && isDecimal(value)) {
      value = negIntegerLength + decimalSeparator + decimalLength;
    } else {
      value = posIntegerLength;
    }
    return value; 
  }

  const inputProps = {
    value: isEmptyUndefinedOrNull(inputValue) ? '' : 
    props.inputType === 'formattedNumber' ? formatValue(inputValue ?? undefined) : inputValue?.toString(),
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newInput = e.target.value;
    
    if (props.inputType === 'number') {
      newInput = limitCharAmount(limitPastedValues(newInput))
      setInputValue(newInput as T);
      onChange && onChange(newInput as T);
      }
    else if (props.inputType === 'formattedNumber') {
      newInput = limitCharAmount(limitPastedValues(removeNonNumericChars(newInput)))
      setInputValue(newInput as T);
      if (isInvalidValue(newInput)) {
        return;
      } else {
          onChange && onChange(newInput.replace(decimalSeparator, '.') as T);
        }
      }      
    else {
      setInputValue(newInput as T);
      onChange && onChange(newInput as T);
    }

    },
    onKeyPress: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (props.inputType === 'number' && !/[0-9]/.test(e.key)) {
        e.preventDefault();
      }
    },
    // How to Disable Scrolling on HTML Number Input in React
    // https://medium.com/modernnerd-code/how-to-disabled-scrolling-on-html-number-input-in-react-6548841166fb
    onWheel: (e: React.UIEvent<HTMLElement>)  => {
      if(props.inputType === 'formattedNumber' || props.inputType === 'number') {
        (e.target as HTMLElement).blur();//The blur event fires when an element has lost focus. 
        e.stopPropagation();//further propagation of the current event
        setTimeout(() => {
          (e.target as HTMLElement).focus()
        }, 0)
      }
    },
  };
  
  return <PlasmicTextInput
    {...plasmicProps}
    input={inputProps}
    textarea={{ ...inputProps, readOnly: props.isDisabled, style: { resize: "vertical" } }}
  />;
}

const TextInput = React.forwardRef(TextInput_);

export default Object.assign(TextInput, {
  __plumeType: "text-input"
});
