import * as React from "react";
import { DefaultMapPlaceholderProps } from "./plasmic/imbas_23_fpre/PlasmicMapPlaceholder";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { Wrapper } from "@googlemaps/react-wrapper";
import { useEffect, useRef, useState } from "react";
import { Country, useRegionContext } from "../context/RegionContext";
import { useGoogleMapContext } from "../context/GoogleMapContext";
import { toast } from "react-toastify";
import { t } from "i18next";
import styles from '../GoogleMapStyles.json';
import { useIdTokenClaims } from "../auth/authUtils";
import { uniq } from "lodash";

const DEFAULT_ZOOM = 17;
const CH_CENTER = { lat: 46.798502, lng: 8.231785 };
const CH_ZOOM = 9;
const LI_CENTER = { lat: 47.13817, lng: 9.55183 };
const LI_ZOOM = 11;
const DE_CENTER = { lat: 51.0, lng: 9.0 };
const DE_ZOOM = 7;
const LU_CENTER = { lat: 49.777222, lng: 6.095278 };
const LU_ZOOM = 10;

export interface MapPlaceholderProps extends DefaultMapPlaceholderProps {
  allowedCountries: (string | Country)[]
}

const GoogleMapsWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  if (!apiKey) {
    return <div>Cannot display the map: google maps api key missing</div>;
  }

  return <Wrapper apiKey={apiKey}>{children}</Wrapper>;
};

const GoogleMaps = (props: MapPlaceholderProps) => {

  const geocoder = new google.maps.Geocoder();
  const ref = useRef<HTMLDivElement | null>(null);
  const { location, country, setCountry } = useRegionContext();
  const defaultCenter = CH_CENTER;
  const {
    mapLocation,
    isCustomAddress,
    centerOnLocationChange,
    setMapLocation,
    setCenterOnLocationChange } = useGoogleMapContext();
  const [marker, setMarker] = useState<google.maps.Marker>();
  const [map, setMap] = useState<google.maps.Map>();
  const [street, setStreet] = useState(location?.Street);
  const [number, setNumber] = useState(location?.Number);
  const [zipCode, setZipCode] = useState(location?.ZipCode);
  const [city, setCity] = useState(location?.City);
  const [wgs84Lat, setWgs84Lat] = useState(location?.WGS84Lat);
  const [wgs84Lng, setWgs84Lng] = useState(location?.WGS84Lng);
  const [fpreIsAccurate, setFpreIsAccurate] = useState(location?.FpreIsAccurate);
  const allowedModules = useIdTokenClaims()?.fpre_allowedModules?.ModulInfo;
  const allowedModuleCountries = allowedModules
    ? uniq(allowedModules.map(({ Land }) => Country[Land]))
    : Object.values(Country); // Fallback to all countries if claims are not available

  useEffect(() => {
    // Display the map
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {
        center: {
          lat: mapLocation?.WGS84Lat || defaultCenter.lat,
          lng: mapLocation?.WGS84Lng || defaultCenter.lng
        },
        zoom: DEFAULT_ZOOM,
        zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_TOP },
        streetViewControlOptions: { position: google.maps.ControlPosition.RIGHT_TOP },
        fullscreenControl: true,
        styles: styles
      }));
    }
    map?.addListener('click', function (e: { latLng: google.maps.LatLng; }) {
      marker?.setPosition(e.latLng);
      if (isCustomAddress === true) {
        setWgs84Lat(e.latLng.lat())
        setWgs84Lng(e.latLng.lng())
      }
      else {
        geocodeLatLng(geocoder, e.latLng, allowedModuleCountries);
      }
    })
  }, [ref, map]);

  useEffect(() => {
    setMarker(new google.maps.Marker({
      map,
      position: {
        lat: mapLocation?.WGS84Lat || defaultCenter.lat,
        lng: mapLocation?.WGS84Lng || defaultCenter.lng
      },
      draggable: true,
      crossOnDrag: true
    }));
  }, [map]);

  useEffect(() => {
    if (marker && marker != null) {
      if (isCustomAddress === true) {
        setWgs84Lat(marker.getPosition()?.lat())
        setWgs84Lng(marker.getPosition()?.lng())
      }
    }
    marker?.addListener('dragend', function (e: { latLng: google.maps.LatLng; }) {
      if (isCustomAddress === true) {
        setWgs84Lat(e.latLng.lat())
        setWgs84Lng(e.latLng.lng())
      }
      else {
        geocodeLatLng(geocoder, e.latLng, allowedModuleCountries);
      }
    })
    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker])

  useEffect(() => {
    zoomToCountry(country);
    if (marker && marker != null) {
      if (isCustomAddress === true) {
        setWgs84Lat(marker.getPosition()?.lat())
        setWgs84Lng(marker.getPosition()?.lng())
      }
    }
  }, [country])

  useEffect(() => {
    if (mapLocation?.WGS84Lat && mapLocation?.WGS84Lng) {
      if (marker && marker != null) {
        marker.setPosition({ lat: mapLocation?.WGS84Lat, lng: mapLocation?.WGS84Lng });
      }
      if (map) {
        if (centerOnLocationChange) {
          map?.setCenter({ lat: mapLocation?.WGS84Lat, lng: mapLocation?.WGS84Lng })
          map?.setZoom(DEFAULT_ZOOM);
          setCenterOnLocationChange(false);
        }
      }
    }
    else {
      zoomToCountry(country);
    }
  }, [mapLocation])

  useEffect(() => {
    setMapLocation({
      Street: street ?? "",
      Number: number ?? "",
      ZipCode: zipCode ?? "",
      City: city ?? "",
      LAND: country ?? "",
      WGS84Lat: wgs84Lat,
      WGS84Lng: wgs84Lng,
      Precision: 3,
      FpreIsAccurate: fpreIsAccurate
    })
  }, [street, number, zipCode, city, wgs84Lat, wgs84Lng, fpreIsAccurate])

  const zoomToCountry = (country: Country) => {
    if (map) {
      switch (country) {
        case Country.CH:
          map.setCenter(CH_CENTER);
          map.setZoom(CH_ZOOM);
          break;
        case Country.DE:
          map.setCenter(DE_CENTER);
          map.setZoom(DE_ZOOM);
          break;
        case Country.LI:
          map.setCenter(LI_CENTER);
          map.setZoom(LI_ZOOM);
          break;
        case Country.LU:
          map.setCenter(LU_CENTER);
          map.setZoom(LU_ZOOM);
          break;
      }
    }
    if (marker) {
      switch (country) {
        case Country.CH:
          marker.setPosition(CH_CENTER);
          break;
        case Country.DE:
          marker.setPosition(DE_CENTER);
          break;
        case Country.LI:
          marker.setPosition(LI_CENTER);
          break;
        case Country.LU:
          marker.setPosition(LU_CENTER);
          break;
      }
    }
  }

  const geocodeLatLng = (
    geocoder: google.maps.Geocoder,
    latLng: google.maps.LatLng,
    allowedCountries: (string | Country)[]
  ) => {

    geocoder
      .geocode({ location: latLng })
      .then((response) => {

        if (response.results[0]) {

          let isValidCountry = true;

          // Error toast if country is outside allowed countries
          response.results[0].address_components.forEach(function (component) {
            component.types.forEach(function (type) {
              if (type === "country") {
                if (Object.values(Country).includes(component.short_name as keyof typeof Country) === false) {
                  isValidCountry = false;
                  toast.error(t("toast.country_not_supported"), { toastId: "validation_error" });
                }
                else if (allowedCountries.includes(Country[component.short_name as keyof typeof Country]) === false) {
                  isValidCountry = false;
                  toast.error(t("toast.country_not_allowed"), { toastId: "validation_error" });
                }
              }
            });
          });

          if (isValidCountry === true) {

            setWgs84Lat(latLng.lat())
            setWgs84Lng(latLng.lng())

            setStreet("")
            setNumber("")
            setZipCode("")
            setCity("")

            let hasStreet = false;
            let hasStreetNumber = false;
            let hasPostalCode = false;
            let hasLocality = false;
            response.results[0].address_components.forEach(function (component) {              
              component.types.forEach(function (type) {
                if (type === "route") {
                  setStreet(component.short_name);
                  hasStreet = true;
                }
                if (type === "street_number") {
                  setNumber(component.short_name);
                  hasStreetNumber = true;
                }
                if (type === "postal_code") {
                  setZipCode(component.short_name);
                  hasPostalCode = true;
                }
                if (type === "locality") {
                  setCity(component.short_name);
                  hasLocality = true
                }
                if (type === "country") {
                  setCountry(Country[component.short_name as keyof typeof Country]);
                }
              });

              const isValidAddress = hasStreet && hasStreetNumber && hasPostalCode && hasLocality;
              if (isValidAddress) {
                setFpreIsAccurate(true);
              }
            });
          }

        } else {
          toast.error(t("toast.address_not_found"), { toastId: "validation_error" });
        }
      })
      .catch((e) => {
        console.log("Geocoder failed due to: " + e);
        toast.error(t("toast.address_not_found"), { toastId: "validation_error" });
      }
      );
  }

  return (
    <div
      ref={ref}
      style={{ width: "100%", flexGrow: 1 }}
    />
  );
};

function MapPlaceholder_(
  props: MapPlaceholderProps,
  ref: HTMLElementRefOf<"div">
) {

  const allowedModules = useIdTokenClaims()?.fpre_allowedModules?.ModulInfo;
  const allowedModuleCountries = allowedModules
    ? uniq(allowedModules.map(({ Land }) => Country[Land]))
    : Object.values(Country); // Fallback to all countries if claims are not available

  return (
    <GoogleMapsWrapper>
      <GoogleMaps allowedCountries={allowedModuleCountries} />
    </GoogleMapsWrapper>
  )
}

const MapPlaceholder = React.forwardRef(MapPlaceholder_);
export default MapPlaceholder;
